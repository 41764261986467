import React, { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/maker">
          <Maker />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

function Maker() {
  const [color, setColor] = useState("gray");
  return (
    <div className="App">
      <h1>おにゃがメーカー</h1>
      <div className="canvas">
        <SvgComponent color={color} />
      </div>

      <div className="picker">
        <CirclePicker
          width="325px"
          circleSize={40}
          color={color}
          onChange={(v) => setColor(v.hex)}
        />
      </div>
    </div>
  );
}

const NOT_HIMO_MESSAGE = "なん、、だと、、🥺";
const HIMO_MESSAGE = "君色に染まりたいにゃ！";
const CONFIM_MESSAGES = [
  "悪いがヒモにならせていただくにゃ！",
  "ヒモにせぇゆーとるじゃ！ぼけがぁ！！",
  "やしにゃって🥺",
  "将来の夢はヒモになることチュア！",
  "しつこいにゃ🙂",
];

function Home() {
  const [message, setMessage] = useState("にゃー🐱");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setTimeout(() => {
      if (window.confirm("ヒモとして養にゃってくれるにゃ？")) {
        setMessage(HIMO_MESSAGE);
      } else {
        setMessage(NOT_HIMO_MESSAGE);
      }
    }, 5000);
  }, []);

  useEffect(() => {
    const date = new Date();
    const confimMessage =
      CONFIM_MESSAGES[date.getTime() % CONFIM_MESSAGES.length];
    setTimeout(() => {
      if (message === NOT_HIMO_MESSAGE) {
        if (window.confirm(confimMessage)) {
          setMessage(HIMO_MESSAGE);
        } else {
          setMessage(NOT_HIMO_MESSAGE);
          setDate(date);
        }
      }
    }, 1000);
  }, [message, date]);

  return (
    <div className="App">
      <h1 className="h1">Welcome to onyaga.com</h1>
      <div className="canvas1 fadein">
        <SvgComponent1 color={"gray"} />
        <div className="comment delay">
          ＼ <br />
          {message}　 <br />／
        </div>
      </div>
      {message === HIMO_MESSAGE && (
        <div className="button-wrapper">
          <Link className="button" to="/maker">
            おにゃがをカスタマイズする
          </Link>
        </div>
      )}
    </div>
  );
}

type SvgProps = {
  color: string;
};

function SvgComponent(props: SvgProps) {
  return (
    <svg
      id="prefix__\u30EC\u30A4\u30E4\u30FC_1"
      data-name="\u30EC\u30A4\u30E4\u30FC 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="270 200 500 500"
      preserveAspectRatio="none"
    >
      <defs>
        <style>
          {
            ".prefix__cls-3{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}"
          }
        </style>
      </defs>
      <path
        d="M428.1 413.7s-9.9-1.6-24.7-11.6c-21.5-14.5-23.5-46.6 1.3-75.7 14.9-17.4 15.1-17.2 22.5-26.7 19.3-25 28.5-44.7 33-43.9 11.3 1.8-4.4 34.4 11.5 36.3 9.1 1.1 15.2 2.3 22.8 2 20.3-.8 20.6-32.5 25.5-32.2s8.1 17.9 15.3 46.9c6 24.3 9.9 41.7 7.3 58.8-3.8 24.6-24.6 25.8-25.9 49.6-.7 11.6 3.6 15.7 12.1 36.3s26.1 63.8 22.2 108.9c-1.9 21-7.7 34.7-4.1 36.3s18.1-16.1 26.2-34.3c9.6-21.4 15.1-37.9 12.8-63.5-5-53.8-8.5-71-8.7-95.9-.2-18.3 6.5-66.2 42.3-90.7 6.3-4.3 37.1-24.1 64.6-10.1 19.7 10 25.5 31.2 26.2 34.3s7.4 30.8-10.1 46.4c-10.5 9.4-32 21.9-39.4 7.6s7-21 14.2-25c11.1-6.3 14.3-17.1 8.4-28.9-3.4-6.8-9-13.9-24.5-12.6-22.3 2-34.9 18.5-41.7 28.6-12.7 18.9-10.6 38.9-10.6 48.5 0 35.5 3.6 54.3 7.1 95.1 2.1 25.6-.3 59.6-10.8 85.2-2.4 5.6-13.5 41.4-45.6 63.6-55.4 38.2-151.5 40.3-177.8-16-36.9-78.8 53.8-182 48.6-217.3z"
        fill={props.color}
      />
      <path
        d="M415.7 407.4s-9.9-1.7-24.6-11.6c-21.6-14.6-23.6-46.7 1.2-75.8 14.9-17.4 15.1-17.2 22.5-26.7 19.4-25 28.5-44.6 33-43.9 11.4 1.8-4.4 34.4 11.5 36.3 9.1 1.1 15.2 2.3 22.8 2 20.3-.8 20.6-32.5 25.5-32.2s8.2 18 15.3 46.9c6 24.4 9.9 41.8 7.3 58.9-3.7 24.6-24.6 25.7-25.9 49.5-.7 11.6 3.6 15.7 12.1 36.3s26.2 63.8 22.2 108.9c-1.9 21.1-7.7 34.8-4.1 36.4s18.1-16.1 26.3-34.3c9.5-21.5 15-38 12.7-63.5-5-53.9-8.5-71.1-8.7-95.9-.2-18.4 6.5-66.2 42.4-90.8 6.2-4.3 37-24.1 64.5-10.1 19.8 10 25.5 31.2 26.2 34.3s7.4 30.8-10 46.4c-10.6 9.4-32.1 21.9-39.5 7.6s7-21 14.2-25c11.2-6.3 14.3-17 8.4-28.9-3.3-6.8-9-13.8-24.5-12.5-22.3 1.9-34.9 18.4-41.7 28.6-12.6 18.9-10.6 38.8-10.6 48.5 0 35.5 3.6 54.2 7.1 95.1 2.2 25.6-.3 59.6-10.8 85.1-2.4 5.7-13.5 41.4-45.6 63.6-55.3 38.3-151.5 40.3-177.8-16-36.9-78.8 53.8-181.9 48.6-217.2z"
        strokeWidth={10}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx={434.9}
        cy={325.6}
        rx={10.9}
        ry={7.4}
        transform="rotate(-82.6 435.034 325.514)"
      />
      <ellipse
        cx={470.4}
        cy={328.4}
        rx={10.9}
        ry={7.4}
        transform="rotate(-82.6 470.49 328.429)"
      />
      <path
        className="prefix__cls-3"
        d="M419 347.9a33.6 33.6 0 00-18.1-1.2 32.7 32.7 0 00-10.1 3.9M418.7 358.3a21.2 21.2 0 00-14.3.4 23.7 23.7 0 00-7.5 4.9M487.9 354.3a34.4 34.4 0 0118-1.1 33.4 33.4 0 0110.2 3.9M488.1 364.7a22 22 0 0114.3.5 25.6 25.6 0 017.6 4.9"
      />
    </svg>
  );
}

function SvgComponent1(props: SvgProps) {
  return (
    <div className="svg">
      <svg
        data-name="\u30EC\u30A4\u30E4\u30FC 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="270 200 500 500"
        preserveAspectRatio="none"
      >
        <defs>
          <style>
            {
              ".prefix__cls-3{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}"
            }
          </style>
        </defs>
        <path
          d="M428.1 413.7s-9.9-1.6-24.7-11.6c-21.5-14.5-23.5-46.6 1.3-75.7 14.9-17.4 15.1-17.2 22.5-26.7 19.3-25 28.5-44.7 33-43.9 11.3 1.8-4.4 34.4 11.5 36.3 9.1 1.1 15.2 2.3 22.8 2 20.3-.8 20.6-32.5 25.5-32.2s8.1 17.9 15.3 46.9c6 24.3 9.9 41.7 7.3 58.8-3.8 24.6-24.6 25.8-25.9 49.6-.7 11.6 3.6 15.7 12.1 36.3s26.1 63.8 22.2 108.9c-1.9 21-7.7 34.7-4.1 36.3s18.1-16.1 26.2-34.3c9.6-21.4 15.1-37.9 12.8-63.5-5-53.8-8.5-71-8.7-95.9-.2-18.3 6.5-66.2 42.3-90.7 6.3-4.3 37.1-24.1 64.6-10.1 19.7 10 25.5 31.2 26.2 34.3s7.4 30.8-10.1 46.4c-10.5 9.4-32 21.9-39.4 7.6s7-21 14.2-25c11.1-6.3 14.3-17.1 8.4-28.9-3.4-6.8-9-13.9-24.5-12.6-22.3 2-34.9 18.5-41.7 28.6-12.7 18.9-10.6 38.9-10.6 48.5 0 35.5 3.6 54.3 7.1 95.1 2.1 25.6-.3 59.6-10.8 85.2-2.4 5.6-13.5 41.4-45.6 63.6-55.4 38.2-151.5 40.3-177.8-16-36.9-78.8 53.8-182 48.6-217.3z"
          fill={props.color}
        />
        <path
          d="M415.7 407.4s-9.9-1.7-24.6-11.6c-21.6-14.6-23.6-46.7 1.2-75.8 14.9-17.4 15.1-17.2 22.5-26.7 19.4-25 28.5-44.6 33-43.9 11.4 1.8-4.4 34.4 11.5 36.3 9.1 1.1 15.2 2.3 22.8 2 20.3-.8 20.6-32.5 25.5-32.2s8.2 18 15.3 46.9c6 24.4 9.9 41.8 7.3 58.9-3.7 24.6-24.6 25.7-25.9 49.5-.7 11.6 3.6 15.7 12.1 36.3s26.2 63.8 22.2 108.9c-1.9 21.1-7.7 34.8-4.1 36.4s18.1-16.1 26.3-34.3c9.5-21.5 15-38 12.7-63.5-5-53.9-8.5-71.1-8.7-95.9-.2-18.4 6.5-66.2 42.4-90.8 6.2-4.3 37-24.1 64.5-10.1 19.8 10 25.5 31.2 26.2 34.3s7.4 30.8-10 46.4c-10.6 9.4-32.1 21.9-39.5 7.6s7-21 14.2-25c11.2-6.3 14.3-17 8.4-28.9-3.3-6.8-9-13.8-24.5-12.5-22.3 1.9-34.9 18.4-41.7 28.6-12.6 18.9-10.6 38.8-10.6 48.5 0 35.5 3.6 54.2 7.1 95.1 2.2 25.6-.3 59.6-10.8 85.1-2.4 5.7-13.5 41.4-45.6 63.6-55.3 38.3-151.5 40.3-177.8-16-36.9-78.8 53.8-181.9 48.6-217.2z"
          strokeWidth={10}
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          cx={434.9}
          cy={325.6}
          rx={10.9}
          ry={7.4}
          transform="rotate(-82.6 435.034 325.514)"
        />
        <ellipse
          cx={470.4}
          cy={328.4}
          rx={10.9}
          ry={7.4}
          transform="rotate(-82.6 470.49 328.429)"
        />
        <path
          className="prefix__cls-3"
          d="M419 347.9a33.6 33.6 0 00-18.1-1.2 32.7 32.7 0 00-10.1 3.9M418.7 358.3a21.2 21.2 0 00-14.3.4 23.7 23.7 0 00-7.5 4.9M487.9 354.3a34.4 34.4 0 0118-1.1 33.4 33.4 0 0110.2 3.9M488.1 364.7a22 22 0 0114.3.5 25.6 25.6 0 017.6 4.9"
        />
      </svg>
      <div className="mouse delay"></div>
      <div className="caption">おにゃが</div>
    </div>
  );
}
